const initiateAlternate = function(elem = null) {
    if(!isElement(elem)) {return false;}
    let text = elem.innerText;
    elem?.appendChild(strToDom(`
        <div class="active" data-default="">${typeof text == "string" && text.length > 0 ? text : (_texts ? (_texts["input-text-placeholder"] ?? "") : "")}</div>
        <input class="inactive"/>`
    ));
    elem?.classList.remove("edit-mode");
    elem?.classList.add("show-mode");
}

const elementAlternatifTexte = function(elem, showCallback = null, editCallback = null) {
    if(!isElement(elem)) {return false;}
    if(showCallback != null && !isFunction(showCallback)) {return false;}
    if(editCallback != null && !isFunction(editCallback)) {return false;}

    if(elem.childElementCount == 0) {
        initiateAlternate(elem);
    }

    let _div = selectOneIn('div', elem);
    let _input = selectOneIn('input', elem);
    let _textarea = selectOneIn('textarea', elem);
    let _height = _div.offsetHeight;
    if(elem?.classList.contains('show-mode') && !elem?.classList.contains('disabled')) {
        elem?.classList.replace('show-mode', 'edit-mode');
        _div?.classList.replace('active', 'inactive');
        if(isElement(_input)) {
            _input.value = _div.innerText == _div.getAttribute('data-default') ? "" : _div.innerText;
            _input?.classList.replace('inactive', 'active');
            _input.focus();
        }
        if(isElement(_textarea)) {
            _textarea.innerText = _div.innerText == _div.getAttribute('data-default') ? "" : _div.innerText;
            _textarea.style.setProperty('height', _height);
            _textarea?.classList.replace('inactive', 'active');
            _textarea.focus();
        }
        isFunction(editCallback) && editCallback(getById(elem.getAttribute('data-for')), elem);
        return true;
    }
    else if(elem?.classList.contains('edit-mode')) {
        elem?.classList.replace('edit-mode', 'show-mode');
        _div?.classList.replace('inactive', 'active');
        if(isElement(_input)) {
            _div.innerText = _input.value.length ? _input.value : _div.getAttribute('data-default');
            _input?.classList.replace('active', 'inactive');
            _input.focus();
        }
        if(isElement(_textarea)) {
            _div.innerText = _textarea.value.length ? _textarea.value : _div.getAttribute('data-default');
            _textarea.style.setProperty('height', _height);
            _textarea?.classList.replace('active', 'inactive');
            _textarea.focus();
        }
        isFunction(showCallback) && showCallback(getById(elem.getAttribute('data-for')), elem);
        return true;
    }
    return false;
}

const initElementAlternatifTexte = function(elem, showCallback = null, editCallback = null) {
    if(!isElement(elem)) {return false;}
    if(showCallback != null && !isFunction(showCallback)) {return false;}
    if(editCallback != null && !isFunction(editCallback)) {return false;}
    elem.addEventListener('click', function(e) {
        elem?.classList.contains('show-mode') && elementAlternatifTexte(elem, showCallback, editCallback);
        // TODO Ajouter écouteur de frappe aux textarea pour ajuster leur height en temps réel
    });
    elem.addEventListener('focusout', function() {
        elem?.classList.contains('edit-mode') && elementAlternatifTexte(elem, showCallback, editCallback);
    });
    elem.addEventListener('blur', function() {
        elem?.classList.contains('edit-mode') && elementAlternatifTexte(elem, showCallback, editCallback);
    });
    elem.addEventListener('keydown', function(e) {
        if(e.key == "Enter") {
            e.preventDefault();
            selectOneIn('input', elem) != null && elementAlternatifTexte(elem, showCallback, editCallback);
        }
    });
    elem.addEventListener('keyup', function(e) {
        if(e.key == "Enter") {
            selectOneIn('input', elem) != null && elementAlternatifTexte(elem, showCallback, editCallback);
        }
    });
    return true;
}
